
import { useDateFns } from '@/composables/locale/useDateFns'
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts'
import { useToggle } from '@/composables/useToggle'
import { computed, defineComponent } from 'vue'
import BaseModal from '../base/BaseModal.vue'
import { useSchedules } from '@/composables/schedule/useSchedules'
import ScheduleStatus from './ScheduleStatus.vue'
import { noCache } from '@/composables/utils/noCache'

export default defineComponent({
  components: { BaseModal, ScheduleStatus },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
  },
  
  setup (props) {
    const {
      isActive: open,
      setInactive: close
    } = useToggle(false)
    
    const {
      socialIcons
    } = useSocialAccounts()

    const {
      format
    } = useDateFns()

    const {
      remove: removeSchedule,
      getStatus
    } = useSchedules()

    const time = computed(() => {
      return format(props.item.customData.scheduledTo, 'HH:mm')
    })

    const scheduledTo = computed(() => {
      return format(props.item.customData.scheduledTo, 'dd/MM/yyyy HH:mm')
    })

    const status = getStatus(props.item.customData.isPublished, props.item.customData.scheduledTo)

    const fallbackThumbnail = computed(() => {
      return require('@/assets/img/no-image.png')
    })

    const thumbnail = computed(() => {
      return props.item.customData.thumbnail ? noCache(props.item.customData.thumbnail) : null
    })

    const remove = async () => {
      await removeSchedule(props.item.customData.id)
      close()
    }

    return {
      socialIcons,
      time,
      fallbackThumbnail,
      scheduledTo,
      status,
      open,
      close,
      remove,
      thumbnail
    }
  }
})
