
import { defineComponent, onBeforeMount } from 'vue'
import DashboardContent from '@/components/dashboard/DashboardContent.vue';
import TheCalendar from '@/components/schedule/TheCalendar.vue';
import { useSchedules } from '@/composables/schedule/useSchedules';

export default defineComponent({
  components: {
    DashboardContent,
    TheCalendar
  },
  setup () {
    const {
      fetch,
      schedules
    } = useSchedules()

    onBeforeMount(async () => {
      await fetch()
    })
    
    return {
      schedules
    }
  }
})
