<template>
  <base-modal
    v-model:open="open" hide-footer size="medium"
  >
    <template v-slot:button>
      <button
        class="text-xs leading-tight rounded-lg mt-0 mb-1 p-1 w-full border bg-gray-50 hover:bg-gray-100"
      >
        <div class="flex justify-start items-start space-x-1">
          <div class="">
            <img
              class="rounded object-cover w-32"
              v-if="thumbnail"
              :src="thumbnail"
              :alt="item.customData.title"
              @error="addDefaultSrc"
            />
            <img
              v-else
              :src="require('@/assets/img/no-image.png')"
              />
          </div>
          
          <div class="flex flex-col justify-start items-start gap-1 mt-1">
            <div class="flex justify-center items-center w-full text-xs gap-1">
              <i
                class="h-4 w-4 rounded-full text-white fab text-xs flex justify-center items-center"
                :class="socialIcons[item.customData.socialNetwork].class"
                :style="socialIcons[item.customData.socialNetwork].style"
              />
              <div>{{ time }}</div>
            </div>
            <schedule-status class="!m-0"
              :isPublished="item.customData.isPublished"
              :scheduledTo="item.customData.scheduledTo"
              icon-only
            />
          </div>
          
        </div>
      </button>
    </template>

    <div class="card-body p-0">
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        <div class="p-4">
          <div class="aspect-w-1 aspect-h-1">
            <img
              class="rounded object-cover"
              v-if="thumbnail"
              :src="thumbnail"
              :alt="item.customData.title"
              @error="addDefaultSrc"
            />
            <img
              v-else
              :src="require('@/assets/img/no-image.png')"
            />
          </div>
          <div
            class="py-4 h-52 whitespace-pre-wrap select-none overflow-y-auto"
          >
            <div>{{ item.customData.content }}</div>
          </div>
        </div>
        <div class="lg:col-span-2 relative">
          <div class="p-4 mb-16">
            <div class="card-title" v-if="item.customData.title">
              {{ item.customData.title }}
            </div>
            <div>
              <ul class="py-4 md:py-8">
                <li class="flex justify-between items-center w-full my-4">
                  <span>{{ $t('sentence.social_network') }}</span>
                  <span class="capitalize font-semibold text-right ml-2">
                    <i
                      class="h-5 w-5 rounded-full text-white fab text-sm inline-flex justify-center items-center"
                      :class="socialIcons[item.customData.socialNetwork].class"
                      :style="socialIcons[item.customData.socialNetwork].style"
                    />
                    {{ item.customData.socialNetwork }}
                  </span>
                </li>
                <li class="flex justify-between items-center w-full my-4">
                  <span>{{ $t('page.publish.sentence.publish_in') }}</span>
                  <span class="font-semibold text-right ml-2">{{ scheduledTo }}</span>
                </li>
                <li class="flex justify-between items-center w-full my-4">
                  <span>{{ $t('sentence.schedule_status') }}</span>
                  <schedule-status
                    :isPublished="item.customData.isPublished"
                    :scheduledTo="item.customData.scheduledTo"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div class="flex justify-end space-x-3 absolute bottom-0 card-footer w-full pt-4">
            <button class="btn" @click.prevent="close">
              {{ $t('action.close') }}
            </button>
            <button
              class="btn btn-secondary"
              @click.prevent="remove"
              v-if="status === 'waiting'"
            >
              <i class="far fa-calendar-times"></i> {{ $t('action.schedule_cancel') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script lang="ts">
import { useDateFns } from '@/composables/locale/useDateFns'
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts'
import { useToggle } from '@/composables/useToggle'
import { computed, defineComponent } from 'vue'
import BaseModal from '../base/BaseModal.vue'
import { useSchedules } from '@/composables/schedule/useSchedules'
import ScheduleStatus from './ScheduleStatus.vue'
import { noCache } from '@/composables/utils/noCache'

export default defineComponent({
  components: { BaseModal, ScheduleStatus },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
  },
  
  setup (props) {
    const {
      isActive: open,
      setInactive: close
    } = useToggle(false)
    
    const {
      socialIcons
    } = useSocialAccounts()

    const {
      format
    } = useDateFns()

    const {
      remove: removeSchedule,
      getStatus
    } = useSchedules()

    const time = computed(() => {
      return format(props.item.customData.scheduledTo, 'HH:mm')
    })

    const scheduledTo = computed(() => {
      return format(props.item.customData.scheduledTo, 'dd/MM/yyyy HH:mm')
    })

    const status = getStatus(props.item.customData.isPublished, props.item.customData.scheduledTo)

    const fallbackThumbnail = computed(() => {
      return require('@/assets/img/no-image.png')
    })

    const thumbnail = computed(() => {
      return props.item.customData.thumbnail ? noCache(props.item.customData.thumbnail) : null
    })

    const remove = async () => {
      await removeSchedule(props.item.customData.id)
      close()
    }

    return {
      socialIcons,
      time,
      fallbackThumbnail,
      scheduledTo,
      status,
      open,
      close,
      remove,
      thumbnail
    }
  }
})
</script>
