
import { defineComponent, readonly } from 'vue'
import { Calendar } from 'v-calendar';
import CalendarItem from './CalendarItem.vue';

export default defineComponent({
  components: {
    Calendar,
    CalendarItem
  },

  props: {
    attributes: {
      type: Array,
      default: () => ([])
    },
  },

  setup () {
    const masks = readonly({
      weekdays: 'WWW',
    })
    
    return {
      masks
    }
  }
})
